
import Particles from "particles.vue"
import Vue from "vue"

Vue.use(Particles)

export default {
    props: {
        activator: {
            default: 0,
        },
        /** Starts animation initially */
        initial: {
            type: Boolean,
            default: false,
        },
        /** Amount of particles per emit */
        amount: {
            type: Number,
            default: 100,
        },
    },
    data: () => ({
        id: null,
    }),
    computed: {
        particleOptions() {
            return {
                fullScreen: {
                    enable: false,
                },
                particles: {
                    number: {
                        value: 0,
                    },
                    color: {
                        value: ["#3643ef", "#ff6e91", "#9400d1", "#00e8b9"],
                    },
                    shape: {
                        type: ["circle", "square"],
                    },
                    opacity: {
                        value: { min: 0, max: 1 },
                        animation: {
                            enable: true,
                            speed: 1,
                            startValue: "max",
                            destroy: "min",
                        },
                    },
                    size: {
                        value: { min: 2, max: 5 },
                    },
                    life: {
                        duration: {
                            sync: true,
                            value: 7,
                        },
                        count: 1,
                    },
                    move: {
                        enable: true,
                        gravity: {
                            enable: true,
                        },
                        drift: {
                            min: -2,
                            max: 2,
                        },
                        speed: {
                            min: 25,
                            max: 75,
                        },
                        angle: {
                            offset: 0,
                            value: 30,
                        },
                        decay: 0.15,
                        direction: "bottom-right",
                        random: false,
                        straight: false,
                        outModes: {
                            default: "destroy",
                            top: "none",
                        },
                    },
                    rotate: {
                        value: {
                            min: 0,
                            max: 360,
                        },
                        direction: "random",
                        move: true,
                        animation: {
                            enable: true,
                            speed: 60,
                        },
                    },
                    tilt: {
                        direction: "random",
                        enable: true,
                        move: true,
                        value: {
                            min: 0,
                            max: 360,
                        },
                        animation: {
                            enable: true,
                            speed: 60,
                        },
                    },
                    wobble: {
                        distance: 35,
                        enable: true,
                        move: true,
                        speed: {
                            min: -55,
                            max: 55,
                        },
                    },
                },
                emitters: [
                    {
                        direction: "bottom-right",
                        position: {
                            x: -5,
                            y: -5,
                        },
                        autoPlay: true,
                        fill: true,
                        life: {
                            wait: true,
                            delay: 2,
                            duration: 2,
                            count: 3,
                        },
                        rate: {
                            quantity: this.amount,
                            delay: 0.5,
                        },
                        shape: "square",
                        startCount: 0,
                        size: {
                            mode: "percent",
                            height: 10,
                            width: 10,
                        },
                    },
                    {
                        direction: "bottom-left",
                        position: {
                            x: 105,
                            y: -5,
                        },
                        autoPlay: true,
                        fill: true,
                        life: {
                            wait: true,
                            delay: 2,
                            duration: 2,
                            count: 3,
                        },
                        rate: {
                            quantity: this.amount,
                            delay: 0.5,
                        },
                        shape: "square",
                        startCount: 0,
                        size: {
                            mode: "percent",
                            height: 10,
                            width: 10,
                        },
                    },
                ],
            }
        },
    },
    watch: {
        activator() {
            this.$refs.particles.container.refresh()
            this.$refs.particles.container.play()
        },
    },
    created() {
        this.id = this.$getUID()
    },
    methods: {
        particlesLoaded() {
            this.$refs.particles.container.start()
            if (this.initial) {
                this.$refs.particles.container.play()
            }
        },
    },
}
